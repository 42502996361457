import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Header from "../components/header"
import { Wrapper } from "../styles/globalstyles"

const NotFoundPage = () => (
  <Wrapper>
    <SEO title="404: Not found" />
    <Header button={true} />
    <h1>NOT FOUND</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
  </Wrapper>
)

export default NotFoundPage
