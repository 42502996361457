import React, {useEffect, useState} from 'react';
import Image from "../components/image";
import {motion} from 'framer-motion';
import {css} from '@emotion/react';


const variants = {
    initial: {opacity: 0, y:-50},
    animate: {opacity: 1, y: 0, transition: {ease: "easeOut", delay: 0.4}}
}

const Header = (props) => {
    const [show, setShow] = useState(true)
    const [modal, setModal] = useState(false)
    const handleScroll = (e) => {
        return window.scrollY > 200 ? setShow(false) : setShow(true)}
    const handleModal = () => {setModal(!modal)}
    useEffect(() => {
      window.addEventListener("scroll", handleScroll, false);
      return () => window.removeEventListener("scroll", handleScroll, false);
      },[]);
    return(
        <> 
        <motion.div
        css={css`
        height: auto;
        width: 4em;
        left: 9%;
        top: 2%;
        position: fixed; 
        z-index: 8;
        `}
        variants={variants}
        initial="initial"
        animate={show ? "animate" : "initial"}
        
        >
          <Image image="logofull" />
          <h3>Akademie / Srum</h3>
        </motion.div>
       

    </>
    )


 }
 export default Header;